import { LAYOUT_NAME } from 'layouts/type'
import { USER_ROLES, LIST_MODULE } from 'services/user/type.d'

export enum ADMIN_ACTIVE_KEY_MENU {
  DASHBOARD = '',
  CUSTOMERS = 'CUSTOMERS',
  SUPPORT = 'SUPPORT',
  WPS = 'WPS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  ENQUIRY = 'ENQUIRY',
  REPORTS = 'REPORTS',
  SETTINGS = 'SETTINGS',
  ADMIN = 'ADMIN'
}

export enum ROUTER_NAME_LIST {
  DASHBOARD = 'DASHBOARD',
  LOGIN = 'LOGIN',

  CUSTOMERS = 'CUSTOMERS',
  SUPPORT_CUSTOMERS = 'SUPPORT_CUSTOMERS',
  EMPLOYEE_DETAIL = 'EMPLOYEE_DETAIL',

  SALARY_DISBURSAL_DETAIL = 'SALARY_DISBURSAL_DETAIL',
  SPOKE_DETAIL_FRONT_PAGE = 'SPOKE_DETAIL_FRONT_PAGE',
  SPOKE_DETAIL_FRONT_PAGE_PHONE = 'SPOKE_DETAIL_FRONT_PAGE_PHONE',
  SUPPORT_SPOKE_DETAIL_FRONT_PAGE = 'SUPPORT_SPOKE_DETAIL_FRONT_PAGE',
  SUPPORT_SPOKE_DETAIL_FRONT_PAGE_PHONE = 'SUPPORT_SPOKE_DETAIL_FRONT_PAGE_PHONE',
  SPOKE_DETAIL_BACK_PAGE = 'SPOKE_DETAIL_BACK_PAGE',
  SPOKE_DETAIL_BACK_PAGE_PHONE = 'SPOKE_DETAIL_BACK_PAGE_PHONE',
  SUPPORT_SPOKE_DETAIL_BACK_PAGE = 'SUPPORT_SPOKE_DETAIL_BACK_PAGE',
  SUPPORT_SPOKE_DETAIL_BACK_PAGE_PHONE = 'SUPPORT_SPOKE_DETAIL_BACK_PAGE_PHONE',
  SPOKE_EDIT_SPOKE = 'SPOKE_EDIT_SPOKE',
  SPOKE_PREVIEW_EXIST_REQUEST_EDIT_SPOKE = 'SPOKE_PREVIEW_EXIST_REQUEST_EDIT_SPOKE',
  SPOKE_REQUEST_EDIT_MANAGEMENT = 'SPOKE_REQUEST_EDIT_MANAGEMENT',
  SPOKE_EDIT_EXIST_REQUEST_EDIT_SPOKE = 'SPOKE_EDIT_EXIST_REQUEST_EDIT_SPOKE',

  CORPORATE_DETAIL_FRONT_PAGE = 'CORPORATE_DETAIL_FRONT_PAGE',
  CORPORATE_DETAIL_BACK_PAGE = 'CORPORATE_DETAIL_BACK_PAGE',
  CORPORATE_DETAIL__RELATIONSHIP_MANAGEMENT = 'CORPORATE_DETAIL__RELATIONSHIP_MANAGEMENT',
  CORPORATE_DETAIL_BACK_PAGE__FILE_MANAGEMENT = 'CORPORATE_DETAIL_BACK_PAGE__FILE_MANAGEMENT',
  CORPORATE_ADD_NEW_CORPORATE = 'CORPORATE_ADD_NEW_CORPORATE',
  CORPORATE_EDIT_REQUEST_ADD_CORPORATE = 'CORPORATE_EDIT_REQUEST_ADD_CORPORATE',
  CORPORATE_ONLY_PREVIEW_CORPORATE_AFTER_SUBMIT = 'CORPORATE_ONLY_PREVIEW_CORPORATE_AFTER_SUBMIT',
  CORPORATE_MANAGEMENT = 'CORPORATE_MANAGEMENT',
  CORPORATE_VIEW_AND_MAINTAIN_UPLOADED_EMPLOYEE_LIST = 'CORPORATE_VIEW_AND_MAINTAIN_UPLOADED_EMPLOYEE_LIST',
  CORPORATE_REQUEST_EDIT_CORPORATE = 'CORPORATE_REQUEST_EDIT_CORPORATE',
  CORPORATE_EDIT_UNREGISTERED_ACCOUNT = 'CORPORATE_EDIT_UNREGISTERED_ACCOUNT',
  CORPORATE_EDIT_PENDING_ACCOUNT = 'CORPORATE_EDIT_PENDING_ACCOUNT',
  CORPORATE_EDIT_PENDING_AND_UNREGISTERED_ACCOUNT_PREVIEW = 'CORPORATE_EDIT_PENDING_AND_UNREGISTERED_ACCOUNT_PREVIEW',
  CORPORATE_EDIT_REQUEST_PENDING_AND_UNREGISTERED_ACCOUNT = 'CORPORATE_EDIT_REQUEST_PENDING_AND_UNREGISTERED_ACCOUNT',

  HUB_EDIT_HUB = 'HUB_EDIT_HUB',
  HUB_PREVIEW_EXIST_REQUEST_EDIT_HUB = 'HUB_PREVIEW_EXIST_REQUEST_EDIT_HUB',
  HUB_REQUEST_EDIT_MANAGEMENT = 'HUB_REQUEST_EDIT_MANAGEMENT',
  HUB_EDIT_EXIST_REQUEST_EDIT_HUB = 'HUB_EDIT_EXIST_REQUEST_EDIT_HUB',
  HUB_DETAIL_FRONT_PAGE = 'HUB_DETAIL_FRONT_PAGE',
  HUB_DETAIL_FRONT_PAGE_PHONE = 'HUB_DETAIL_FRONT_PAGE_PHONE',
  SUPPORT_HUB_DETAIL_FRONT_PAGE = 'SUPPORT_HUB_DETAIL_FRONT_PAGE',
  HUB_DETAIL_BACK_PAGE = 'HUB_DETAIL_BACK_PAGE',
  HUB_DETAIL_BACK_PAGE_PHONE = 'HUB_DETAIL_BACK_PAGE_PHONE',
  SUPPORT_HUB_DETAIL_BACK_PAGE = 'SUPPORT_HUB_DETAIL_BACK_PAGE',

  WPS = 'WPS',
  WPS_FILE = 'WPS_FILE',
  WPS_PAYMENT_FILES = 'WPS_PAYMENT_FILES',
  WPS_RETURN_FILES = 'WPS_RETURN_FILES',
  WPS_REFUND_FILES = 'WPS_REFUND_FILES',
  WPS_EXCEPTIONALQUEUE_ARCHIVE = 'WPS_EXCEPTIONALQUEUE_ARCHIVE',

  ENQUIRY = 'ENQUIRY',
  TRANSACTION_HISTORY = 'TRANSACTION_HISTORY',
  TRANSACTION_HISTORY_BATCHES = 'TRANSACTION_HISTORY_BATCHES',

  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATIONS_DETAILS = 'NOTIFICATIONS_DETAILS',
  NOTIFICATIONS_CREATE_CAMPAIGN = 'NOTIFICATIONS_CREATE_CAMPAIGN',

  REPORTS = 'REPORTS',

  SETTINGS = 'SETTINGS',
  FX_RATES = 'FX_RATES',
  LIMIT_MANAGEMENT = 'LIMIT_MANAGEMENT',
  REMITTANCE_FEES = 'REMITTANCE_FEES',
  CREATE_USER_GROUP = 'CREATE_USER_GROUP',
  CREATE_USER = 'CREATE_USER',
  CREATE_CORPORATE_PRICING = 'CREATE_CORPORATE_PRICING',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  VIEW_DETAIL_USER = 'VIEW_DETAIL_USER',
  PREVIEW_DETAIL_USER = 'PREVIEW_DETAIL_USER',
  VIEW_DETAIL_USER_GROUP = 'VIEW_DETAIL_USER_GROUP',
  PREVIEW_USER_GROUP = 'PREVIEW_USER_GROUP',
  USER_GROUP_MANAGEMENT = 'USER_GROUP_MANAGEMENT',
  EDIT_USER_GROUP = 'EDIT_USER_GROUP',
  EDIT_USER_GROUP_REQUEST = 'EDIT_USER_GROUP_REQUEST',
  EDIT_USER = 'EDIT_USER',
  EDIT_USER_REQUEST = 'EDIT_USER_REQUEST',
  EDIT_CORPORATE_PRICING = 'EDIT_CORPORATE_PRICING',
  EDIT_CORPORATE_PRICING_REQUEST = 'EDIT_CORPORATE_PRICING_REQUEST',
  EDIT_MONTHLY_SUBSCRIPTION = 'EDIT_MONTHLY_SUBSCRIPTION',
  EDIT_MONTHLY_SUBSCRIPTION_REQUEST = 'EDIT_MONTHLY_SUBSCRIPTION_REQUEST',
  CORPORATE_PRICING_MANAGEMENT = 'CORPORATE_PRICING_MANAGEMENT',
  DETAIL_ONBOARDING_REQUEST = 'DETAIL_ONBOARDING_REQUEST',

  DETAIL_MONTHLY_SUBSCRIPTION_REQUEST = 'DETAIL_MONTHLY_SUBSCRIPTION_REQUEST',

  ADMIN_PAGE = 'ADMIN_PAGE',
  ADMIN_SANCTIONED_COUNTRIES = 'ADMIN_SANCTIONED_COUNTRIES',
  ADMIN_INTERNAL_ACCOUNT_MANAGEMENT = 'ADMIN_INTERNAL_ACCOUNT_MANAGEMENT',
  ADMIN_INTERNAL_ACCOUNT_DETAILS = 'ADMIN_INTERNAL_ACCOUNT_DETAILS',
  ADMIN_INTERNAL_ACCOUNT_MANAGEMENT_REQUEST_DETAILS = 'ADMIN_INTERNAL_ACCOUNT_MANAGEMENT_REQUEST_DETAILS',
  ADMIN_INTERNAL_ACCOUNT_EDIT_DETAILS = 'ADMIN_INTERNAL_ACCOUNT_EDIT_DETAILS',
  ADMIN_INTERNAL_ACCOUNT_EDIT_DETAILS_REQUEST = 'ADMIN_INTERNAL_ACCOUNT_EDIT_DETAILS_REQUEST',

  SUPPORTS = 'SUPPORTS',
  CALL_CENTER_SUPPORTS = 'CALL_CENTER_SUPPORTS',
  SUPPORTS__VIEW_REQUESTS_OF_UNREGISTERED_ACCOUNT = 'SUPPORTS__VIEW_REQUESTS_OF_UNREGISTERED_ACCOUNT',

  RM_PROFILE = 'RM_PROFILE',
  FIRST_TIME_LOGIN = 'FIRST_TIME_LOGIN',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export const listRouterMapping: any = {
  [ROUTER_NAME_LIST.DASHBOARD]: LIST_MODULE.DASHBOARD,

  [ROUTER_NAME_LIST.CUSTOMERS]: LIST_MODULE.CUSTOMERS,
  [ROUTER_NAME_LIST.SUPPORT_CUSTOMERS]: LIST_MODULE.CUSTOMERS,
  [ROUTER_NAME_LIST.EMPLOYEE_DETAIL]: LIST_MODULE.HUB_ACCOUNTS,

  [ROUTER_NAME_LIST.SALARY_DISBURSAL_DETAIL]: LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.SPOKE_DETAIL_FRONT_PAGE]: LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SPOKE_DETAIL_FRONT_PAGE_PHONE]: LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SUPPORT_SPOKE_DETAIL_FRONT_PAGE]:
    LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SUPPORT_SPOKE_DETAIL_FRONT_PAGE_PHONE]:
    LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SPOKE_DETAIL_BACK_PAGE]: LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SPOKE_DETAIL_BACK_PAGE_PHONE]: LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SUPPORT_SPOKE_DETAIL_BACK_PAGE]: LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SUPPORT_SPOKE_DETAIL_BACK_PAGE_PHONE]:
    LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SPOKE_EDIT_SPOKE]: LIST_MODULE.EDIT_SPOKE_USER_DETAILS,
  [ROUTER_NAME_LIST.SPOKE_PREVIEW_EXIST_REQUEST_EDIT_SPOKE]:
    LIST_MODULE.SPOKE_ACCOUNTS,
  [ROUTER_NAME_LIST.SPOKE_REQUEST_EDIT_MANAGEMENT]:
    LIST_MODULE.SPOKE_MANAGEMENT,
  [ROUTER_NAME_LIST.SPOKE_EDIT_EXIST_REQUEST_EDIT_SPOKE]:
    LIST_MODULE.SPOKE_ACCOUNTS,

  [ROUTER_NAME_LIST.CORPORATE_DETAIL_FRONT_PAGE]: LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_DETAIL_BACK_PAGE]: LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_DETAIL__RELATIONSHIP_MANAGEMENT]:
    LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_DETAIL_BACK_PAGE__FILE_MANAGEMENT]:
    LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_ADD_NEW_CORPORATE]: LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_EDIT_REQUEST_ADD_CORPORATE]:
    LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_ONLY_PREVIEW_CORPORATE_AFTER_SUBMIT]:
    LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_MANAGEMENT]: LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_VIEW_AND_MAINTAIN_UPLOADED_EMPLOYEE_LIST]:
    LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_REQUEST_EDIT_CORPORATE]: LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_EDIT_UNREGISTERED_ACCOUNT]:
    LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_EDIT_PENDING_ACCOUNT]: LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_EDIT_PENDING_AND_UNREGISTERED_ACCOUNT_PREVIEW]:
    LIST_MODULE.CORPORATES,
  [ROUTER_NAME_LIST.CORPORATE_EDIT_REQUEST_PENDING_AND_UNREGISTERED_ACCOUNT]:
    LIST_MODULE.CORPORATES,

  [ROUTER_NAME_LIST.HUB_EDIT_HUB]: LIST_MODULE.EDIT_HUB_USER_DETAILS,
  [ROUTER_NAME_LIST.HUB_PREVIEW_EXIST_REQUEST_EDIT_HUB]:
    LIST_MODULE.HUB_ACCOUNTS,
  [ROUTER_NAME_LIST.HUB_REQUEST_EDIT_MANAGEMENT]: LIST_MODULE.HUB_MANAGEMENT,
  [ROUTER_NAME_LIST.HUB_EDIT_EXIST_REQUEST_EDIT_HUB]: LIST_MODULE.HUB_ACCOUNTS,
  [ROUTER_NAME_LIST.HUB_DETAIL_FRONT_PAGE]: LIST_MODULE.HUB_ACCOUNTS,
  [ROUTER_NAME_LIST.HUB_DETAIL_FRONT_PAGE_PHONE]: LIST_MODULE.HUB_ACCOUNTS,
  [ROUTER_NAME_LIST.SUPPORT_HUB_DETAIL_FRONT_PAGE]: LIST_MODULE.HUB_ACCOUNTS,
  [ROUTER_NAME_LIST.HUB_DETAIL_BACK_PAGE]: LIST_MODULE.HUB_ACCOUNTS,
  [ROUTER_NAME_LIST.HUB_DETAIL_BACK_PAGE_PHONE]: LIST_MODULE.HUB_ACCOUNTS,

  [ROUTER_NAME_LIST.WPS]: LIST_MODULE.WPS,
  [ROUTER_NAME_LIST.WPS_FILE]: LIST_MODULE.WPS_FILE,
  [ROUTER_NAME_LIST.WPS_PAYMENT_FILES]: LIST_MODULE.WPS_FILE,
  [ROUTER_NAME_LIST.WPS_RETURN_FILES]: LIST_MODULE.WPS_FILE,
  [ROUTER_NAME_LIST.WPS_REFUND_FILES]: LIST_MODULE.WPS_FILE,
  [ROUTER_NAME_LIST.WPS_EXCEPTIONALQUEUE_ARCHIVE]:
    LIST_MODULE.WPS_EXCEPTIONAL_QUEUE,

  [ROUTER_NAME_LIST.ENQUIRY]: LIST_MODULE.ENQUIRY,
  [ROUTER_NAME_LIST.TRANSACTION_HISTORY]: LIST_MODULE.ENQUIRY,
  [ROUTER_NAME_LIST.TRANSACTION_HISTORY_BATCHES]: LIST_MODULE.ENQUIRY,

  [ROUTER_NAME_LIST.NOTIFICATIONS]: LIST_MODULE.NOTIFICATIONS,
  [ROUTER_NAME_LIST.NOTIFICATIONS_DETAILS]: LIST_MODULE.NOTIFICATIONS,
  [ROUTER_NAME_LIST.NOTIFICATIONS_CREATE_CAMPAIGN]: LIST_MODULE.NOTIFICATIONS,

  [ROUTER_NAME_LIST.REPORTS]: LIST_MODULE.REPORTS,

  [ROUTER_NAME_LIST.SETTINGS]: LIST_MODULE.SETTINGS,
  [ROUTER_NAME_LIST.FX_RATES]: LIST_MODULE.FX_RATES,
  [ROUTER_NAME_LIST.LIMIT_MANAGEMENT]: LIST_MODULE.LIMIT_MANAGEMENT,
  [ROUTER_NAME_LIST.REMITTANCE_FEES]: LIST_MODULE.TRANSACTION_FEES,
  [ROUTER_NAME_LIST.CREATE_USER_GROUP]: LIST_MODULE.USER_GROUP,
  [ROUTER_NAME_LIST.CREATE_USER]: LIST_MODULE.USER,
  [ROUTER_NAME_LIST.CREATE_CORPORATE_PRICING]: LIST_MODULE.CORPORATE_PRICING,
  [ROUTER_NAME_LIST.USER_MANAGEMENT]: LIST_MODULE.USER,
  [ROUTER_NAME_LIST.VIEW_DETAIL_USER]: LIST_MODULE.USER,
  [ROUTER_NAME_LIST.PREVIEW_DETAIL_USER]: LIST_MODULE.USER,
  [ROUTER_NAME_LIST.VIEW_DETAIL_USER_GROUP]: LIST_MODULE.USER_GROUP,
  [ROUTER_NAME_LIST.PREVIEW_USER_GROUP]: LIST_MODULE.USER_GROUP,
  [ROUTER_NAME_LIST.USER_GROUP_MANAGEMENT]: LIST_MODULE.USER_GROUP,
  [ROUTER_NAME_LIST.EDIT_USER_GROUP]: LIST_MODULE.USER_GROUP,
  [ROUTER_NAME_LIST.EDIT_USER_GROUP_REQUEST]: LIST_MODULE.USER_GROUP,
  [ROUTER_NAME_LIST.EDIT_USER]: LIST_MODULE.USER,
  [ROUTER_NAME_LIST.EDIT_USER_REQUEST]: LIST_MODULE.USER,
  [ROUTER_NAME_LIST.EDIT_CORPORATE_PRICING]: LIST_MODULE.CORPORATE_PRICING,
  [ROUTER_NAME_LIST.DETAIL_MONTHLY_SUBSCRIPTION_REQUEST]:
    LIST_MODULE.CORPORATE_PRICING,
  [ROUTER_NAME_LIST.EDIT_CORPORATE_PRICING_REQUEST]:
    LIST_MODULE.CORPORATE_PRICING,
  [ROUTER_NAME_LIST.EDIT_MONTHLY_SUBSCRIPTION]: LIST_MODULE.CORPORATE_PRICING,
  [ROUTER_NAME_LIST.EDIT_MONTHLY_SUBSCRIPTION_REQUEST]:
    LIST_MODULE.CORPORATE_PRICING,
  [ROUTER_NAME_LIST.CORPORATE_PRICING_MANAGEMENT]:
    LIST_MODULE.CORPORATE_PRICING,
  [ROUTER_NAME_LIST.DETAIL_ONBOARDING_REQUEST]: LIST_MODULE.CORPORATE_PRICING,

  [ROUTER_NAME_LIST.ADMIN_PAGE]: LIST_MODULE.ADMIN,
  [ROUTER_NAME_LIST.ADMIN_SANCTIONED_COUNTRIES]: LIST_MODULE.ADMIN,
  [ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_MANAGEMENT]: LIST_MODULE.ADMIN,
  [ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_DETAILS]: LIST_MODULE.ADMIN,
  [ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_EDIT_DETAILS]: LIST_MODULE.ADMIN,
  [ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_EDIT_DETAILS_REQUEST]:
    LIST_MODULE.ADMIN,
  [ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_MANAGEMENT_REQUEST_DETAILS]:
    LIST_MODULE.ADMIN,
  [ROUTER_NAME_LIST.SUPPORTS]: LIST_MODULE.SUPPORT,
  [ROUTER_NAME_LIST.CALL_CENTER_SUPPORTS]: LIST_MODULE.SUPPORT,
  [ROUTER_NAME_LIST.SUPPORTS__VIEW_REQUESTS_OF_UNREGISTERED_ACCOUNT]:
    LIST_MODULE.SUPPORT
}

export interface RouterItemType {
  component: Any
  exact?: boolean
  path: string
  isPrivate?: boolean
  accessWhiteList?: USER_ROLES[]
  validate?: any
  layout: LAYOUT_NAME
  logoConfig?: {
    link?: string
    isDisable: boolean
  }
  adminActiveMenuKey?: ACTIVE_KEY
  name: ROUTER_NAME_LIST
}

export interface IRouteConfigProps {
  adminActiveMenuKey?: ACTIVE_KEY
  isPrivate?: boolean
  layout: LAYOUT_NAME
  name: ROUTER_NAME_LIST
}
