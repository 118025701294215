import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'

import { authHeader, isExpiredJWT, serialize, isDeactive } from './helper'
import UserService from 'services/user/user.service'
import { IAxiosHeader } from './type'
import AuthService from 'services/user/auth.service'
import { showDeactiveMessage } from 'helpers/message'

const initialHeaders: IAxiosHeader = authHeader()

const http: any = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: initialHeaders
})

export const cancelHttp: CancelTokenSource = axios.CancelToken.source()

const getNewTokenIfExpired: any = (): string => {
  return UserService.getTokenFromLocalStorage()
}

http.fetch = (url: string, params?: any): Promise<any> => {
  const query: string = serialize(params || {})
  return http.get(`${url}${query}`)
}

http.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<any> => {
    const newToken: string = await getNewTokenIfExpired()
    config.headers = {
      ...config.headers,
      Authorization: newToken ? `Bearer ${newToken}` : ''
    }
    config.baseURL = process.env.REACT_APP_API_URL

    return config
  }
)

/*
http.interceptors.response.use(
  (response: any): any => {
    return response
  },
  (err: any): any => {
    console.log('----res-err:', err)

    return new Promise((resolve?: any, reject?: any): Promise<any> => {
      const currentRequest: any = err.config

      if (
        isExpiredJWT()
      ) {
        currentRequest._retry = true
        const res: any = simpleHttp
          .post('/refresh', {
            token: localStorage.getItem('token'),
            refresh_token: localStorage.getItem('refresh_token')
          })
          .then((_res: any): any => {
            UserService.setCurrentUser(_res.data)
            // this.setSession({ token: res.token, refresh_token: res.refresh })
            // currentRequest.headers['Token'] = _res.token
            // currentRequest.headers['Device'] = 'device'
            http.headers = {
              ...http.headers,
              Authorization: _res?.data?.userDataKey
                ? `Bearer ${_res.data.userDataKey}`
                : ''
            }
            return http(currentRequest)
          })
        resolve(res)
      }
      return Promise.reject(err)
    })
  }
)
*/

http.interceptors.response.use(
  (response: any): any => {
    return Promise.resolve(response)
  },
  (err: any): any => {
    if (isExpiredJWT(err)) {
      cancelHttp.cancel('The request was canceled')
    }

    if (isDeactive(err)) {
      showDeactiveMessage('Your account is deactivated')
      AuthService.logout()
    }

    return Promise.reject(err)
  }
)

export { http }
