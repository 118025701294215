import { FC, Suspense, useEffect, useMemo } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { Spin } from 'antd'

import { routers } from 'router'
import {
  IRouteConfigProps,
  listRouterMapping,
  RouterItemType
} from 'router/type.d'
import { useCheckIsLogedIn, IUseUserRole, useUserRole } from 'hooks'
import { GradientAdminLayout } from './Admin'
import { AuthenLayout } from './Authen'
import { BlankLayout } from './Blank'
import { PageWrapper } from './PageWrapper'

import { HaveNotPermission } from 'components/HaveNotPermission'

import { StoreType } from 'redux/type'
import { IProps, LAYOUT_NAME } from './type.d'
import { UserType } from 'services/user/type.d'
import { setCurrentListNotAccess } from 'redux/reducers/listAccessSlice'
import store from 'redux/store'
import { setCurrentPrevPath } from 'redux/reducers/prevPathSlice'

const LAYOUT: any = {
  [LAYOUT_NAME.ADMIN]: GradientAdminLayout,
  [LAYOUT_NAME.AUTHEN]: AuthenLayout,
  [LAYOUT_NAME.BLANK]: BlankLayout
}

const FallbackLoading: FC = (): JSX.Element => {
  return (
    <div className="fallback-loading">
      <Spin />
    </div>
  )
}

export const Layout: FC<IProps> = ({
  children,
  ...props
}: IProps): JSX.Element => {
  const location: any = useLocation()

  const currentRoute: RouterItemType | null = useSelector(
    (state: StoreType): RouterItemType | null => state.router.current,
    shallowEqual
  )
  const user: UserType = useSelector(
    (state: StoreType): UserType => state.user.currentUser,
    shallowEqual
  )

  const listNotAccess: string[] = useSelector(
    (state: StoreType): string[] => state.listNotAccess.currentListNotAccess,
    shallowEqual
  )

  // tslint:disable-next-line: typedef
  useEffect(() => {
    setTimeout((): void => {
      store.dispatch(
        setCurrentPrevPath(`${location.pathname}${location.search}`)
      )
    }, 1)

    if (location.pathname.includes('/customer-support')) {
      sessionStorage.setItem('isCustomerSupport', '1')
    }
    const storage: string[] = JSON.parse(
      JSON.stringify(localStorage.getItem('listNonAccess'))
    )

    store.dispatch(setCurrentListNotAccess(storage))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const role: IUseUserRole = useUserRole()

  const layoutName: LAYOUT_NAME = useMemo((): LAYOUT_NAME => {
    return currentRoute?.layout || LAYOUT_NAME.BLANK
  }, [currentRoute])

  const CurrentLayout: FC = LAYOUT[layoutName] || BlankLayout
  const isLoggedIn: boolean = useCheckIsLogedIn(user)

  return (
    <CurrentLayout>
      <Suspense fallback={<FallbackLoading />}>
        <Switch>
          {routers.map((router: RouterItemType, index: number): JSX.Element => {
            const { path, exact, isPrivate, component: Page } = router

            return (
              <Route
                path={path}
                exact={exact}
                key={index}
                render={(routeProps: any): any => {
                  const haveNoRightToAccess: boolean = Boolean(
                    listNotAccess?.includes(listRouterMapping[router.name])
                  )
                  const havePermissionToAccess: boolean =
                    !router.accessWhiteList
                      ? true
                      : role.isInsideGroup(router.accessWhiteList)

                  if ((isPrivate && isLoggedIn) || !isPrivate) {
                    const routeConfig: IRouteConfigProps = {
                      layout: router.layout,
                      isPrivate: router.isPrivate,
                      adminActiveMenuKey: router.adminActiveMenuKey,
                      name: router.name
                    }

                    return (
                      <PageWrapper
                        Page={
                          havePermissionToAccess && !haveNoRightToAccess
                            ? Page
                            : HaveNotPermission
                        }
                        pageProps={{ ...props, ...routeProps, routeConfig }}
                        routeConfig={router}
                        location={location}
                      />
                    )
                  }

                  if (isPrivate && !isLoggedIn) {
                    return <Redirect to="/login" />
                  }

                  return null
                }}
              />
            )
          })}
        </Switch>
      </Suspense>
    </CurrentLayout>
  )
}
